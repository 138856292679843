import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import "./CityList.css";
import * as PinoUtil from "../Common/PinoUtil";




function CityItem(props) {    
    return (
        <li className="city_li_div">
            <Link to={{
                pathname:`/${props.c_data.cid}`, 
                state: {city: 123}
                }} > 

                <img className='city_item_img' src={"res/city/" + props.c_data.cid + ".jpg"} alt={props.c_data.cid}/>
                <div className='city_ltem_title'>{props.c_data.name} </div>
                <img className='city_item_cc' src={"res/cc-flags/" + props.c_data.cc.toLowerCase() + ".png"} alt={props.c_data.cc}/>

            </Link>
            
        </li>
    );
}





function CityList(props) {    

    let  g_city_list = PinoUtil.TMI_ParseCityListData(PinoUtil.TMI_CITY_LIST_STR);

    useEffect(()=> {
        console.log("execute once");
        
        console.log(g_city_list);

    }, [])

    
    return (
        <div id="city_list">
            <div id='main_banner_div'>
                <img id="main_banner_img" src="/res/main_banner_airport.jpg" alt='main_banner' />
                <div id="main_banner_title_div" >
                    <div id="main_banner_title">어디로 떠나볼까요?</div>
                </div>
            </div>

            <div id='country_ul_div'>
                <div id='country_ul'>


                    {
                        PinoUtil.TMI_REGION_CATEGORIES.sort(() => Math.random() - 0.5).map((region_item, index) => (
                            <div key={index} >
                                <div className='country_head'> 
                                    <span className='country_name'>{region_item}</span>
                                </div>
                                <ul className='city_ul'>
                                    {
                                        g_city_list.filter(cd => cd.region === region_item).map((city_data, index) => (
                                            <CityItem  key={index}  c_data={city_data} />
                                        ))
                                    } 
                                </ul>
                            </div>
                        ))
                    }

                    {/*
                        Array.from(PinoUtil.PINO_COUNTRY_LIST).map(([cc_id,cc_obj])  => {
                            return (
                                <div key={cc_id}>
                                    <div className='country_head'>
                                        <img className='country_flag' src={'/res/cc-flags/'+cc_id+'.png'} alt={cc_id}></img>
                                        <span className='country_name'>{cc_obj.country_name}</span>
                                    </div>
                                    <ul className='city_ul'>
                                        {cc_obj.cities.map((city_id, city_obj) => (
                                            <CityItem key={city_id}  city_id={city_id}   city_name={PinoUtil.PINO_CITY_LIST.get(city_id).city_name}  city_img={"res/city/" + city_id + ".jpg"} />
                                        ))}
                                    </ul>
                                </div>
                            );
                        })
                            */
                    }
                </div>
            </div>
        </div>
    );
}

export default CityList; 