import React from "react";
import "./Footer.css";

function Footer(props) {    
    return (
        <div id='Footer'>
            <div id='footer_div'>
                <div id='Footer_contact'>ⓒ 트래블TMI | 관리자: 박종철 | 이메일: the.next.station.corp@gmail.com </div>
            </div>
            
        </div>
    );
}

export default Footer; 