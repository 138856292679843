import React, { useState, useEffect } from "react";
import axios from 'axios';
import {useLocation, Link } from "react-router-dom";
import { Tabs, Tab, Box, Typography } from '@mui/material';
import TourIcon from '@mui/icons-material/Tour';
import HotelIcon from '@mui/icons-material/Hotel';
import CloseIcon from '@mui/icons-material/Close';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import Carousel from 'react-material-ui-carousel';
//import DatePickPage from './DatePickPage';
//import FilterPage from './FilterPage';
import "./CityPage.css";

import * as PinoUtil from "../Common/PinoUtil";



function CityPage(props) {    

    const location = useLocation();

    const TAB_NAMES = ['PLACE','FOOD','HOTEL','ETC'];
    const [cur_tab, set_cur_tab] = useState(0);

    var init_spot_data = {};
    init_spot_data.map_url = '';
    init_spot_data.spots = [];
    const [city_data, set_city_data] = useState(init_spot_data);

    var init_detail_data = {};
    init_detail_data.name = "";
    init_detail_data.img = "";
    init_detail_data.type = "";
    const [detail_data, set_detail_data] = useState(init_detail_data);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    function openPopup(spot_data) {
        set_detail_data(spot_data);
        setIsPopupOpen(true);
        document.body.style.overflow = 'hidden';
    }
    function closePopup() {
        setIsPopupOpen(false);
        document.body.style.overflow = 'auto';
    }


    useEffect(()=> {
        console.log("execute once");

        LoadCityData();

    }, []);



    function handleTabChange(event, new_tab) {
        set_cur_tab(new_tab);
    }



    function LoadCityData() {
        var cur_city_id = location.pathname.split('/')[1];

        axios.get('../data/'+cur_city_id+'.csv')
            .then(function(response) {
                var result = response.data;
                var temp_city_data = PinoUtil.TMI_ParseCityData(result);
                console.log(temp_city_data);
                set_city_data(temp_city_data);
            })
            .catch(function(error) {
                console.log("Error : LoadHotelJson");
            })

    }



    
    return (
        <div id='city_page_div'>

            <iframe src={city_data.map_url} className="iframe_map"></iframe>

            <div id='spot_list_div'>
                <div className="TabList">
                    <Tabs  value={cur_tab} onChange={handleTabChange}   variant="fullWidth" 
                            /*
                           textColor="inherit"  // 기본값을 사용할 때는 'inherit'로 설정
                           indicatorColor="secondary"  // 여기서는 무시되고 sx에서 지정됨
                           aria-label="custom tabs example"
                           sx={{
                             '& .MuiTab-root': { 
                               color: '#000',  // 기본 텍스트 색상
                             },
                             '& .Mui-selected': {
                               color: '#fea61c',  // 선택된 탭 텍스트 색상
                             },
                             '& .MuiTabs-indicator': {
                               backgroundColor: '#fea61c',  // 인디케이터 색상
                             },
                           }}
                             */
                         >
                        <Tab icon={<TourIcon />} iconPosition="top" label="관광" value={0}         sx={{ fontSize: '1.1rem', fontWeight:'bold', }} />
                        <Tab icon={<RestaurantIcon />} iconPosition="top"  label="맛집" value={1}  sx={{ fontSize: '1.1rem', fontWeight:'bold', }}/>
                        <Tab icon={<HotelIcon />} iconPosition="top" label="호텔" value={2}        sx={{ fontSize: '1.1rem', fontWeight:'bold', }}/>
                        <Tab icon={<DirectionsBusIcon />} iconPosition="top" label="기타" value={3}        sx={{ fontSize: '1.1rem', fontWeight:'bold', }}/>
                    </Tabs>
                </div>

                <div className="SpotList">
                    {
                        city_data.spots.map((spot_item, index) => (
                            <div  key={index} onClick={() => openPopup(spot_item)}>
                                <SpotLi key={index} spot_data={spot_item} cur_tab_name={TAB_NAMES[cur_tab]} />
                            </div>
                        ))
                    }
                </div>
            </div>

            <div className='margin_div'></div>
 



            {isPopupOpen && (
                <div className="DetailPagePopup">
                    <div className="dp_close_div">
                        <CloseIcon onClick={closePopup} sx={{ fontSize: 40, color: 'white' }}  />
                    </div>
                    <div className="dp_inner_box">
                        <div className='dp_title'>{detail_data.name}</div>
                        <div className='dp_subtype'>{detail_data.subtype}</div>
                        <div className='dp_body'>

                            {detail_data.img2 == '' && (
                                <Carousel autoPlay={false} animation={"fade"} duration={300} indicators={true} navButtonsAlwaysVisible={false}  >
                                    <ImageItem img_url={ detail_data.img} />
                                </Carousel>
                            )}

                            {detail_data.img2 && detail_data.img3 == '' && (
                                <Carousel autoPlay={false} animation={"fade"} duration={300} indicators={true} navButtonsAlwaysVisible={false}  >
                                    <ImageItem img_url={ detail_data.img} />
                                    <ImageItem img_url={ detail_data.img2} />
                                </Carousel>
                            )}

                            {detail_data.img3 && (
                                <Carousel autoPlay={false} animation={"fade"} duration={300} indicators={true} navButtonsAlwaysVisible={false}  >
                                    <ImageItem img_url={ detail_data.img} />
                                    <ImageItem img_url={ detail_data.img2} />
                                    <ImageItem img_url={ detail_data.img3} />
                                </Carousel>           
                            )}

                            
                            {detail_data.googlemap && (
                                <a className="dp_googlemap_link_a" href={detail_data.googlemap} target='blank'>
                                    <div className="dp_googlemap_link">Google 맵에서 보기</div>
                                </a>
                            )}

                            {detail_data.description && (
                                <div className="dp_data_box">
                                    <div className="dp_data_key">T. M. I.</div>
                                    <div className="dp_data_value" dangerouslySetInnerHTML={{ __html: detail_data.description }}></div>
                                </div>
                            )}

                            {detail_data.type == 'HOTEL' && (
                                <div className="dp_data_box">
                                    <div className="dp_data_key">숙소 정보</div>
                                    <div className="dp_data_value">
                                        { /* detail_data.chain_name  && ( <div> <div className="dp_data_subkey">체인브랜드</div>  <div className="dp_data_subvalue">{detail_data.chain_name}개</div> </div> ) */}
                                        {detail_data.number_of_rooms  && ( <div> <div className="dp_data_subkey">객실 수</div>  <div className="dp_data_subvalue">{parseInt(detail_data.number_of_rooms).toLocaleString()} 개</div> </div> )}
                                        {detail_data.checkin_time  && ( <div> <div className="dp_data_subkey">체크인</div>  <div className="dp_data_subvalue">{detail_data.checkin_time}</div> </div> )}
                                        {detail_data.checkout_time  && ( <div> <div className="dp_data_subkey">체크아웃</div>  <div className="dp_data_subvalue">{detail_data.checkout_time}</div> </div> )}
                                        {detail_data.year_opened  && ( <div> <div className="dp_data_subkey">년식</div>  <div className="dp_data_subvalue">{detail_data.year_opened}년 오픈</div> </div> )}
                                        {detail_data.year_renovated && ( <div> <div className="dp_data_subkey">리모델링</div>  <div className="dp_data_subvalue">{detail_data.year_renovated}년 리모델링</div> </div>)}
                                        {detail_data.hotel_website && ( <div> <div className="dp_data_subkey">홈페이지</div>  <div className="dp_data_subvalue"><a href={detail_data.hotel_website} target='blank'>{detail_data.hotel_website}</a></div> </div>)}
                                    </div>
                                </div>
                            )}

                            {detail_data.price && (
                                <div className="dp_data_box">
                                    <div className="dp_data_key">가격 정보</div>
                                    <div className="dp_data_value" dangerouslySetInnerHTML={{ __html: detail_data.price }}></div>
                                    {detail_data.price_link && (
                                        <div>
                                            <a className="dp_data_linkbox_a" href={detail_data.price_link} target='blank'>
                                                <div className="dp_data_linkbox">예약 ({detail_data.price_link_name})</div>
                                            </a>
                                        </div>
                                    )}
                                </div>
                            )}                            
                            {detail_data.menu && (
                                <div className="dp_data_box">
                                    <div className="dp_data_key">추천 메뉴</div>
                                    <div className="dp_data_value" dangerouslySetInnerHTML={{ __html: detail_data.menu }}></div>
                                </div>
                            )}
                            {detail_data.opening_hour && (
                                <div className="dp_data_box">
                                    <div className="dp_data_key">운영시간</div>
                                    <div className="dp_data_value" dangerouslySetInnerHTML={{ __html: detail_data.opening_hour }}></div>
                                </div>
                            )}
                            {detail_data.website && (
                                <div className="dp_data_box">
                                    <div className="dp_data_key">홈페이지</div>
                                    <div className="dp_data_value"><a href={detail_data.website} target='blank'>{detail_data.website}</a></div>
                                </div>
                            )}
                            {detail_data.youtube && (
                                <div className="dp_data_box">
                                    <div className="dp_data_key">참고 영상</div>
                                    <iframe width="100%" height="300" src={"https://www.youtube.com/embed/"+detail_data.youtube} 
                                            frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                            referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                                </div>
                            )}
                            


                            {detail_data.agoda_id && (
                                <div>
                                    <a className="dp_data_linkbox_a" href={PinoUtil.AGODA_LINK_URL + detail_data.agoda_id} target='blank'>
                                        <div className="dp_data_linkbox">예약 링크 (아고다)</div>
                                    </a>
                                </div>
                            )}


                            <div className="dp_data_box">
                                <div className="dp_data_key">지도</div>
                                <div className="dp_data_map_div">
                                    <iframe
                                        width="100%"  height="200px"  style={{ border: 0 }}  loading="lazy"  allowFullScreen  referrerPolicy="no-referrer-when-downgrade"
                                        src={"https://www.google.com/maps/embed/v1/place?key=AIzaSyAtpGDKBEoXaNjRJ_sTeykecnmxFhkadk4&&q="+detail_data.name}>
                                    </iframe>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            )}


        </div>
    );
}





function SpotLi(props) {

    return (
        <div className='spot_li'  hidden={props.cur_tab_name !== props.spot_data.type}>
            <img className="spot_li_img" src={props.spot_data.img} />
            <div className="spot_li_text">
                <div className="spot_li_title">{props.spot_data.index} {props.spot_data.name}</div>
                <div className="spot_li_subtype">{props.spot_data.subtype}</div>
                {/**
                <div className="spot_li_popularity"> <LocalFireDepartmentIcon />{props.spot_data.popularity}</div>
                 */}
            </div>             
        </div>
    )
}





function ImageItem(props) {
    return (
        <div className='dp_img_div'>
            <img className='dp_img' src={props.img_url} alt=''/>
        </div>
    )
}








/*

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`tabpanel-${index}`}
          aria-labelledby={`tab-${index}`}
          {...other}
        >
          {value === index && (
            <Box p={3}>
              <Typography>{children}</Typography>
            </Box>
          )}
        </div>
      );
}

            <div>
                <TabPanel value={cur_tab} index={0}>
                    <div>Content for Tab 1</div>
                </TabPanel>
                <TabPanel value={cur_tab} index={1}>
                    <div>Content for Tab 2</div>
                </TabPanel>
                <TabPanel value={cur_tab} index={2}>
                    <div>Content for Tab 3</div>
                </TabPanel>
            </div>

*/


export default CityPage; 