

export const GOOGLE_API_KEY = 'AIzaSyAtpGDKBEoXaNjRJ_sTeykecnmxFhkadk4';


export const PINO_COUNTRY_LIST = new Map([
    ["mo", { country_name:"마카오", cities:['Macau']} ],
    ["hk", { country_name:"홍콩", cities:['HongKong']} ]
]);


export const PINO_CITY_LIST = new Map([
    ["Macau", { city_name:"마카오",  city_map:"https://www.google.com/maps/d/embed?mid=12eefVMssXrmw0ujP7YnqeLpbjT0PTbk&ehbc=2E312F&noprof=1"} ],
    ["HongKong", { city_name:"홍콩",  city_map:"https://www.google.com/maps/d/embed?mid=1xlwHozQqGRf6358pl66q2ukLaNgTQiQ&ehbc=2E312F&noprof=1"} ]
]);



export const TMI_REGION_CATEGORIES = ['동북아'];
//export const TMI_REGION_CATEGORIES = ['한국','일본','동남아','동북아','미주','유럽','인도양','대양주','중동'];


export const TMI_CITY_LIST_STR = 
"137|마카오|Macau|MO|동북아|22.178043|113.556248|https://live.staticflickr.com/5455/8863435693_1895dde067_q.jpg|-480|4|true|zh,pt|MOP||80,83,85,87,86,85,85,84,84,83,81,79|0.4,1.1,1.2,2.8,7.5,6,6.2,7.4,12.6,9.1,2.3,0.4|11.8,12.1,12.4,12.8,13.1,13.3,13.2,12.9,12.6,12.2,11.9,11.7|true|2024-08-14 16:00:47.115|2024-08-14 16:00:47.115|마카오|1|MFM|Macau|https://www.google.com/maps/d/embed?mid=12eefVMssXrmw0ujP7YnqeLpbjT0PTbk&ehbc=2E312F&noprof=1";


/*
export const TMI_CITY_LIST_STR = 
"111|뉴욕|New York City|US|미주|40.7127753|-74.0059728|https://live.staticflickr.com/7893/45616202575_5bbed056b3_q.jpg|240|14|true|en|USD||32.9,35.3,42.2,52.7,62.5,72.2,77.4,76.3,69.1,58.1,48.3,38.2|3.2,2.8,4,4,3.8,3.9,4.5,4.1,3.7,3.8,3.4,3.6|10.2,11.2,12.5,13.8,15,15.6,15.3,14.2,12.9,11.5,10.4,9.8|true|2024-08-14 15:48:42.768|2024-08-14 15:48:42.768|미국|1|JFK|NewYorkCity|\n\
112|로스앤젤리스|Los Angeles|US|미주|34.0522342|-118.2436849|https://live.staticflickr.com/2584/3768340508_57f68e08c3_q.jpg|420|12|true|en|USD||57.3,58.5,60,62.2,64.7,68.4,72.7,73.4,72.2,68.1,63,58.2|3.2,3.4,2.4,1,0.2,0.1,0,0,0.3,0.5,1.2,2.4|10.7,11.4,12.4,13.5,14.4,14.9,14.6,13.8,12.8,11.7,10.8,10.4|true|2024-08-14 15:52:55.993|2024-08-14 15:52:55.993|미국|2|LAX|LosAngeles|\n\
113|샌프란시스코|San Francisco|US|미주|37.7749295|-122.4194155|https://live.staticflickr.com/7141/6663281265_6cd95ea952_q.jpg|420|11|true|en|USD||51,53.9,55.2,56.2,57.5,59.5,59.8,60.6,62.7,61.8,57.4,52|4.4,3.8,2.8,1.4,0.6,0.2,0,0,0.2,1.1,2.6,4.1|10.4,11.3,12.4,13.7,14.7,15.3,15,14,12.8,11.6,10.6,10.1|true|2024-08-14 15:57:57.466|2024-08-14 15:57:57.466|미국|3|SFO|SanFrancisco|\n\
114|하와이|Hawaii|US|미주|20.44554|-159.754372|https://live.staticflickr.com/495/18599647298_4e7f8a4275_q.jpg|600|9|true|en|USD||73.2,73.1,74.5,76.1,77.8,80.2,81.2,81.9,81.5,80,77.6,74.8|2.3,2,2,0.6,0.6,0.3,0.5,0.6,0.7,1.8,2.4,3.2|11.4,11.9,12.4,13,13.6,13.8,13.7,13.2,12.6,12,11.5,11.3|true|2024-08-14 15:58:25.379|2024-08-14 15:58:25.379|미국|4|HNL|Hawaii|\n\
115|발리|Bali|IN|동남아|-8.471997|115.164745|https://live.staticflickr.com/7465/16347759515_da53616a87_q.jpg|-480|7|true|id|IDR||83,83,83,83,82,81,80,80,81,82,83,83|13.7,11.3,8.4,3.7,3,2.8,2,0.9,1.6,3.6,6.1,11.5|12.9,12.7,12.5,12.3,12.1,12,12,12.2,12.4,12.7,12.9,13|true|2024-08-14 15:58:36.752|2024-08-14 15:58:36.752|인도네시아|2|DPS|Bali|\n\
116|푸켓|Phuket|TH|동남아|7.9519331|98.3380884|https://live.staticflickr.com/65535/48050801148_b31acc5293_q.jpg|-420|6|true|vi|VND||81,82,83,84,83,83,82,83,81,81,81,81|1.4,1.2,1.5,6.4,13.7,8.4,10.4,10.3,16.5,12,8.2,2|12.1,12.2,12.4,12.7,12.9,13,12.9,12.7,12.5,12.3,12.1,12|true|2024-08-14 15:58:42.188|2024-08-14 15:58:42.188|태국|2|HKT|Phuket|\n\
117|밀라노|Milan|IT|유럽|45.4642035|9.189982|https://live.staticflickr.com/1778/29024654067_324c86db00_q.jpg|-120|15|true|it|EUR||33.5,37.5,44.2,51.2,59.3,66.3,71.5,70.2,64,54.1,42.7,35|2.7,3,3.9,4.2,5.2,3.7,2.6,3.8,2.9,4.2,4.2,2.1|9.7,10.9,12.5,14.1,15.5,16.3,15.9,14.6,13,11.4,10,9.3|true|2024-08-14 15:58:48.044|2024-08-14 15:58:48.044|이태리|2|ROM|Milan|\n\
118|런던|London|GB|유럽|51.5073509|-0.1277583|https://live.staticflickr.com/4904/45011277105_e8b9ff1630_q.jpg|-60|15|true|en|GBP||39.7,40,44.3,47.7,53.8,59.1,63.1,62.6,57.7,51.6,44.9,40.5|3.1,2,2.4,2.1,2.2,2.2,1.8,2.2,2.7,2.9,3.1,3.1|9.2,10.6,12.5,14.5,16.3,17.3,16.7,15.1,13.2,11.2,9.5,8.6|true|2024-08-14 15:59:00.420|2024-08-14 15:59:00.420|영국|1|LON|London|\n\
119|파리|Paris|FR|유럽|48.856614|2.3522219|https://live.staticflickr.com/4171/34316486612_4a7d0ca0c1_q.jpg|-120|14|true|fr|EUR||41,42.1,47.8,52.7,59.5,64.9,68.9,68.7,62.4,55.4,46.9,41.9|2,1.6,1.9,2,2.5,2,2.5,2.1,1.9,2.4,2,2.3|9.4,10.8,12.5,14.3,15.9,16.8,16.3,14.9,13.1,11.3,9.8,9|true|2024-08-14 15:59:04.834|2024-08-14 15:59:04.834|프랑스|1|CDG|Paris|\n\
120|로마|Rome|IT|유럽|41.9027835|12.4963655|https://live.staticflickr.com/65535/33911554628_83eedd88a6_q.jpg|-120|13|true|it|EUR||46.9,48.3,51,55.7,62.5,69.1,74.1,74.4,69.6,62.6,54.8,49.1|3.2,3,2.6,2.1,1.2,0.6,0.6,1.3,2.7,3.7,4.3,3.5|10.1,11.1,12.4,13.9,15.1,15.7,15.4,14.3,12.9,11.5,10.3,9.7|true|2024-08-14 15:59:09.424|2024-08-14 15:59:09.424|이태리|1|FCO|Rome|\n\
121|몰디브|Maldives|MV|인도양|2.659854|73.237484|https://live.staticflickr.com/944/28209305368_5ae9951151_q.jpg|-300|13|false|dv|MVR||82,82,83,83,83,82,82,82,82,82,82,81|3,2,2.9,5.2,8.5,6.8,5.8,7.4,9.6,8.7,7.9,9.1|12.3,12.4,12.4,12.6,12.7,12.7,12.7,12.6,12.5,12.4,12.3,12.3|true|2024-08-14 15:59:20.007|2024-08-14 15:59:20.007|몰디브|1|MLE|Maldives|\n\
122|프라하|Prague|CZ|유럽|50.0755381|14.4378005|https://live.staticflickr.com/8654/15808761513_7571f34197_q.jpg|-120|17|false|cs,sk|CZK||29,30,39,45,55,60,63,63,57,47,37,32|0.8,0.7,1,1.4,2.3,2.7,2.6,2.5,1.6,1.2,1.1,0.9|9.3,10.7,12.5,14.4,16.1,17.1,16.6,15,13.1,11.2,9.6,8.8|true|2024-08-14 15:59:25.572|2024-08-14 15:59:25.572|체코|1|PRG|Prague|\n\
123|도쿄|Tokyo|JP|일본|35.6803997|139.7690174|https://live.staticflickr.com/721/31846804332_9076fd6b52_q.jpg|-540|2|true|ja|JPY||43,43.7,48.9,58.3,66,71.8,78.4,81.3,74.8,65.3,55.9,47.7|2,2.8,4.2,5.1,5.7,6.9,5.3,5.8,8.5,7.6,3.8,2.1|10.5,11.4,12.4,13.6,14.5,15,14.8,13.9,12.8,11.7,10.8,10.3|true|2024-08-14 15:59:31.229|2024-08-14 15:59:31.229|일본|1|NRT|Tokyo|\n\
124|오키나와|Okinawa|JP|일본|26.450799|127.923193|https://live.staticflickr.com/697/23826480941_68871aa1e0_q.jpg|-540|2|true|ja|JPY||81.4,81.2,81.8,82.3,82.6,81.8,81.4,81.6,82,82.1,82.5,82|11.2,9.7,8.8,9.4,11.3,17.5,17,14.5,11.7,13.4,11.6,12.3|11.1,11.7,12.4,13.2,13.9,14.2,14,13.4,12.7,11.9,11.3,10.9|true|2024-08-14 15:59:37.525|2024-08-14 15:59:37.525|일본|4|OKA|Okinawa|\n\
125|오사카|Osaka|JP|일본|34.6937249|135.5022535|https://live.staticflickr.com/65535/47942767693_cff8aeb9ee_q.jpg|-540|2|true|ja|JPY||42.8,43.3,48.9,59.2,67.5,74.3,81.3,83.8,77,66.2,56.5,47.5|1.8,2.3,3.9,5.3,5.1,7.9,6.3,4.1,6.8,4.8,2.9,1.8|10.6,11.4,12.4,13.5,14.5,14.9,14.7,13.9,12.8,11.7,10.8,10.3|true|2024-08-14 15:59:41.626|2024-08-14 15:59:41.626|일본|2|KIX|Osaka|\n\
126|시드니|Sydney|AU|대양주|-33.8688197|151.2092955|https://live.staticflickr.com/5758/30111403413_bf9743bbf1_q.jpg|-600|10|true|en|AUD||72,73,70,65,60,55,53,55,59,64,67,71|4,4.5,5.2,4.2,3.9,5.2,2.5,3.2,2.2,3.1,3.4,3.1|14.6,13.7,12.7,11.6,10.8,10.4,10.6,11.3,12.3,13.4,14.3,14.9|true|2024-08-14 15:59:46.785|2024-08-14 15:59:46.785|호주|1|SYD|Sydney|\n\
127|다낭|Da Nang|VN|동남아|16.0544068|108.2021667|https://live.staticflickr.com/65535/45547474411_a7da668405_q.jpg|-420|5|true|vi|VND||72,73,77,80,84,86,85,85,83,79,76,72|4,1.3,1.2,1.3,2.5,3.4,3.6,4.3,12.1,22,13.2,8.8|11.7,12,12.4,12.9,13.3,13.5,13.4,13,12.6,12.1,11.8,11.6|true|2024-08-14 16:00:00.615|2024-08-14 16:00:00.615|베트남|3|DAD|DaNang|\n\
128|두바이|Dubai|AE|중동|25.168863|55.245471|https://live.staticflickr.com/7045/6896645590_974ec2be33_q.jpg|-240|10|true|ar|AED||66,67,72,79,87,90,94,95,90,84,76,69|0.4,1.6,0.4,0.4,0.4,0.4,0.4,0.4,0.4,0.4,0.4,0.8|11.2,11.7,12.4,13.2,13.8,14.1,13.9,13.4,12.7,11.9,11.3,11|true|2024-08-14 16:00:07.330|2024-08-14 16:00:07.330|아랍에미레이트|1|DXB|Dubai|\n\
129|세부|Cebu|PH|동남아|10.3156992|123.8854366|https://live.staticflickr.com/3255/2405706916_382130c5bc_q.jpg|-480|4|true|en|PHP||81,81,82,84,85,84,83,83,83,83,83,81|4.6,2.9,1.1,1.5,3,7,7.2,4.7,6.5,8.2,4.7,4.6|12,12.2,12.4,12.7,13,13.1,13,12.8,12.5,12.3,12,11.9|true|2024-08-14 16:00:11.945|2024-08-14 16:00:11.945|필리핀|2|CEB|Cebu|\n\
130|보라카이|Boracay|PH|동남아|11.9673753|121.924815|https://live.staticflickr.com/5067/5672688856_7d025ebbdc_q.jpg|-480|5|true|en|PHP||79,80,81,83,84,83,82,82,82,82,82,80|5.7,3.6,1.9,2,7.1,10.6,10.9,9,10.3,14.8,12.2,9|11.9,4,12.4,12.8,13,13.2,13.1,12.9,12.5,12.2,12,11.8|true|2024-08-14 16:00:16.220|2024-08-14 16:00:16.220|필리핀|1|KLO|Boracay|\n\
131|후쿠오카|Fukuoka|JP|일본|33.5901838|130.4016888|https://live.staticflickr.com/6004/6013308517_6c427f1da1_q.jpg|-540|1|true|ja|JPY||43.9,45.3,50.7,59.2,66.9,73.4,81,82.6,75.9,66.6,56.8,48|0.9,0.8,1.9,2.9,3.4,4.6,3.8,4,3.8,2.5,1.7,1.1|10.7,11.4,12.4,13.5,14.4,14.8,14.6,13.8,12.8,11.7,10.9,10.4|true|2024-08-14 16:00:20.493|2024-08-14 16:00:20.493|일본|5|FUK|Fukuoka|\n\
132|나트랑|Nha Trang|VN|동남아|12.2387911|109.1967488|https://live.staticflickr.com/2828/11906632014_7ceacb33f3_q.jpg|-420|5|true|vi|VND||76,77,79,82,84,85,84,84,83,81,79,76|1.8,0.8,1.3,1.6,2.1,1.7,1.7,2.1,6.1,12.6,12.7,7.2|11.9,12.1,12.4,12.8,13.1,13.2,13.1,12.9,12.5,12.2,11.9,11.8|true|2024-08-14 16:00:24.826|2024-08-14 16:00:24.826|베트남|4|NHA|NhaTrang|\n\
133|방콕|Bangkok|TH|동남아|13.7563309|100.5017651|https://live.staticflickr.com/65535/48047658306_ebc153484d_q.jpg|-420|6|true|vi|VND||80,83,85,87,86,85,85,84,84,83,81,79|0.4,1.1,1.2,2.8,7.5,6,6.2,7.4,12.6,9.1,2.3,0.4|11.8,12.1,12.4,12.8,13.1,13.3,13.2,12.9,12.6,12.2,11.9,11.7|true|2024-08-14 16:00:29.505|2024-08-14 16:00:29.505|태국|1|BKK|Bangkok|\n\
134|싱가포르|Singapore|SG|동남아|1.281574|103.848647|https://live.staticflickr.com/2774/4456318373_3de7f12009_q.jpg|-480|7|true|en,ms,zh,ta|SGD||81,82,83,83,83,84,83,83,82,82,81,80|9.4,6.5,6.9,6.5,6.7,6.4,5.9,6.7,6.4,7.5,9.8,10.6|12.4,12.4,12.5,12.5,12.5,12.6,12.6,12.5,12.5,12.4,12.4,12.4|true|2024-08-14 16:00:33.875|2024-08-14 16:00:33.875|싱가포르|1|SIN|Singapore|\n\
135|라스베가스|Las Vegas|US|미주|36.1699412|-115.1398296|https://live.staticflickr.com/2804/4182328118_07e21edba5_q.jpg|420|14|false|en|USD||48.7,52.9,59.9,67.2,77.3,86.7,92.5,90.6,82.6,69.5,56.4,47.7|0.5,0.8,0.4,0.2,0.1,0.1,0.4,0.3,0.3,0.3,0.4,0.5|10.5,11.4,12.4,13.6,14.6,15.1,14.8,13.9,12.8,11.7,10.7,10.2|true|2024-08-14 16:00:38.496|2024-08-14 16:00:38.496|미국|5|LAS|LasVegas|\n\
136|홍콩|Hong Kong|HK|동북아|22.3193039|114.1693611|https://live.staticflickr.com/2791/4105285613_8c612d3df9_q.jpg|-480|4|true|zh,en|HKD||62,63,67,73,79,83,85,85,83,79,72,65|1.1,1.7,2.9,5.5,11.2,15.7,14.3,14.8,11.7,4.7,1.5,1|11.3,11.8,12.4,13.1,13.6,13.9,13.8,13.3,12.6,12,11.5,11.2|true|2024-08-14 16:00:43.142|2024-08-14 16:00:43.142|홍콩|1|HKG|HongKong|https://www.google.com/maps/d/embed?mid=1xlwHozQqGRf6358pl66q2ukLaNgTQiQ&ehbc=2E312F&noprof=1\n\
137|마카오|Macau|MO|동북아|22.178043|113.556248|https://live.staticflickr.com/5455/8863435693_1895dde067_q.jpg|-480|4|true|zh,pt|MOP||80,83,85,87,86,85,85,84,84,83,81,79|0.4,1.1,1.2,2.8,7.5,6,6.2,7.4,12.6,9.1,2.3,0.4|11.8,12.1,12.4,12.8,13.1,13.3,13.2,12.9,12.6,12.2,11.9,11.7|true|2024-08-14 16:00:47.115|2024-08-14 16:00:47.115|마카오|1|MFM|Macau|https://www.google.com/maps/d/embed?mid=12eefVMssXrmw0ujP7YnqeLpbjT0PTbk&ehbc=2E312F&noprof=1\n\
138|마드리드|Madrid|ES|유럽|40.4167754|-3.7037902|https://live.staticflickr.com/8805/17160459229_d406810278_q.jpg|-120|15|true|es|EUR||41.7,45,49.6,53.1,60.1,69.3,76.1,75.6,68.4,57.9,48.6,43.5|1.8,1.7,1.5,1.8,1.6,1,0.4,0.4,1.2,1.8,2.5,1.9|10.2,11.2,12.4,13.8,15,15.6,15.2,14.2,12.9,11.6,10.4,9.8|true|2024-08-14 16:00:51.104|2024-08-14 16:00:51.104|스페인|2|MAD|Madrid|\n\
139|괌|Guam|MP|대양주|13.444304|144.793731|https://live.staticflickr.com/2903/14678837133_1bf54e1cd5_q.jpg|-600|4|true|en|USD||80.2,79.9,80.7,81.9,82.6,82.6,81.9,81.4,81.4,81.7,81.8,81.2|5,4.5,2.8,3.6,4.3,7.1,12.1,17.2,14.2,11.8,9.2,6|11.8,12.1,12.4,12.8,13.1,13.3,13.2,12.9,12.6,12.2,11.9,11.7|true|2024-08-14 16:00:55.089|2024-08-14 16:00:55.089|괌|1|GUM|Guam|\n\
140|바르셀로나|Barcelona|ES|유럽|41.38516|2.186786|https://live.staticflickr.com/7834/40087097793_4569017982_q.jpg|-120|14|true|es|EUR||48,49.8,52.3,55.4,61.2,67.8,73.4,74.5,70,62.6,54.5,50|1.6,1.1,1.6,1.9,2.3,1.6,0.8,2.4,3.4,3.6,2.3,2|10.1,11.1,12.4,13.8,15.1,15.7,15.3,14.3,12.9,11.5,10.4,9.8|true|2024-08-14 16:01:00.102|2024-08-14 16:01:00.102|스페인|1|BCN|Barcelona|\n\
141|호치민|Ho Chi Minh|VN|동남아|10.778639|106.70341|https://live.staticflickr.com/48/158972444_b79b65c06a_q.jpg|-420|5|true|vi|VND||80,82,84,86,86,84,83,83,83,82,81,80|0.6,0.1,0.4,2,8.4,12.2,11.6,10.7,13.5,10.3,4.7,1.8|11.9,12.2,12.4,12.7,13,13.1,13.1,12.8,12.5,12.2,12,11.9|true|2024-08-14 16:01:04.049|2024-08-14 16:01:04.049|베트남|2|SGN|HoChiMinh|\n\
142|타이베이|Taipei|TW|동남아|25.048735|121.520982|https://live.staticflickr.com/3845/15059023549_41fbb417f3_q.jpg|-480|3|true|zh|TWD||61,61,65,71,77,82,85,85,81,76,69,63|3.6,5.4,6.6,6.2,8.3,11.7,9.4,10.9,9.7,4.2,2.7,2.9|11.2,11.7,12.4,13.2,13.8,14.1,13.9,13.4,12.7,11.9,11.3,11|true|2024-08-14 16:01:07.693|2024-08-14 16:01:07.693|대만|1|TPE|Taipei|\n\
143|삿포로|Sapporo|JP|일본|43.052313|141.35987|https://live.staticflickr.com/4005/4340886853_9e61cef2fc_q.jpg|-540|3|true|ja|JPY||25.5,26.4,33.1,44.8,54.3,62.1,68.9,72.1,64.6,53.2,40.8,30.4|4.24,3.7,3.2,2.5,2.2,2.6,2.7,5.6,5.4,4.6,3.9,3.9|10,11,12.4,13.9,15.2,15.9,15.6,14.4,13,11.5,10.2,9.6|true|2024-08-14 16:01:11.561|2024-08-14 16:01:11.561|일본|3|SPK|Sapporo|\n\
144|코사무이|Ko Samui|PH|동남아|9.5120168|100.0135929|https://live.staticflickr.com/4615/39784395884_b94e90697e_q.jpg|-480|12|false|en|PHP||79.8,81.1,82.7,84.4,84.6,83.9,83.4,83.3,82.8,81.4,80.2,79.7|5.4,2.3,3.1,3,5.8,4.4,4.8,4.7,4.6,11.4,19.3,8.2|12,12.2,12.4,12.7,12.9,13.1,13,12.8,12.5,12.3,12.1,12|true|2024-08-14 16:01:15.762|2024-08-14 16:01:15.762|필리핀|3|USM|KoSamui|\n\
145|칸쿤|Cancun|MX|미주|21.122289|-86.797394|https://live.staticflickr.com/2635/4113568037_2677105c3b_q.jpg|300|19|false|es|MXN||75.4,77,78.8,81.3,83.8,84.6,85.3,85.5,84.2,81.7,78.8,76.5|4.1,2,1.8,1.1,3.5,5.6,2.7,3.4,7.2,11.1,5,3.5|11.4,11.9,12.4,13,13.6,13.8,13.7,13.2,12.6,12,11.5,11.3|true|2024-08-14 16:01:20.161|2024-08-14 16:01:20.161|멕시코|1|CUN|Cancun|\n\
146|사이판|Saipan|MP|대양주|15.1850483|145.7467259|https://live.staticflickr.com/574/22750458592_45f66b88e9_q.jpg|-600|5|true|en|USD||80,80,81,82,83,84,83,83,83,83,82,82|3.6,2.6,1.9,2.3,2.8,4.5,8.3,12.2,10.2,11.3,5.9,4.2|11.7,12,12.4,12.8,13.2,13.4,13.3,13,12.6,12.2,11.8,11.6|true|2024-08-14 16:01:24.539|2024-08-14 16:01:24.539|사이판|1|SPN|Saipan|\n\
147|마닐라|Manila|PH|동남아|14.553965|121.002819|https://live.staticflickr.com/4188/34621635745_a9e514ae57_q.jpg|-480|4|true|en|PHP||79,80,82,85,85,83,82,81,82,82,81,79|0.8,0.4,0.6,1.2,4.9,10.3,15.9,14.4,13.5,7.8,5.3,2.6|11.8,12,12.4,12.8,13.2,13.4,13.3,13,12.6,12.2,11.8,11.7|true|2024-08-14 16:01:28.511|2024-08-14 16:01:28.511|필리핀|4|MNL|Manila|\n\
148|베니스|Venice|IT|유럽|45.438751|12.328475|https://live.staticflickr.com/4348/36325821542_d8ddb8cee0_q.jpg|-120|15|false|it|EUR||36.4,40,46.2,53.8,62.2,68.6,72.8,71.9,66,56.8,46.1,38|2.3,2.1,2.2,2.5,2.7,3,2.5,3.3,2.6,2.7,3.4,2.1|9.7,10.9,12.5,14.1,15.5,16.3,15.9,14.6,13,11.4,10,9.3|true|2024-08-14 16:01:32.741|2024-08-14 16:01:32.741|이태리|3|VCE|Venice|\n\
149|비엔나|Vienna|AT|유럽|48.2207779|16.3098489|https://live.staticflickr.com/4518/26561711359_f92171d46e_q.jpg|-120|15|false|de|EUR||34.2,37.2,43.5,52.7,61.7,66.4,71.1,70.9,62.2,52.9,41.9,36.3|0.8,1.1,1.5,1.5,2.4,2.5,2.6,2.6,2,1.3,1.7,1.4|9.5,10.8,12.5,14.3,15.9,16.7,16.2,14.8,13.1,11.3,9.8,9|true|2024-08-14 16:01:36.693|2024-08-14 16:01:36.693|오스트리아|1|VIE|Vienna|";
*/


export function TMI_ParseCityListData(raw_data) {    
    var city_list = [];
    const rows = raw_data.split("\n");
    for(let i = 0; i < rows.length; i++){
        //console.log(rows[i]);
        let values = rows[i].split("|");

        var c_json = {};
        c_json.name = values[1];
        c_json.lat = parseFloat(values[5]);
        c_json.lng = parseFloat(values[6]);
        c_json.img = values[7];
        c_json.cid = values[23];
        c_json.cc = values[3];
        c_json.region = values[4];

        city_list.push(c_json);      
    }

    return city_list;
}


export function TMI_ConvertHotelPriceText(price) {
    
    return "1박 평균 가격: "+(price*1350).toLocaleString() + "원";
}


export function TMI_ParseCityData(raw_data) {
    //console.log(raw_data);

    var ret_data = {};
    ret_data.map_url = '';
    ret_data.spots = [];
    
    var place_index=1;
    var food_index=1;
    var hotel_index=1;
    var etc_index=1;
    
    const rows = raw_data.split("\n");
    for(let i = 0; i < rows.length; i++){
        //console.log(rows[i]);

        let values = rows[i].split("|");

        var c_json = {};
        c_json.type = values[0];
        if(c_json.type == 'CITY') {
            ret_data.map_url = values[1];
        }
        else {
            if(c_json.type == 'PLACE') {
                //id, name_kor, img, subtype, website, description, price, price_link, opening_hour, youtube, google_map_link 
                c_json.name = values[2];
                c_json.img = values[3];
                c_json.img2 = '';
                c_json.img3 = '';
                c_json.subtype = values[4];
                c_json.website = values[5];
                c_json.description = values[6];
                c_json.price = values[7];
                c_json.price_link = values[8];
                c_json.opening_hour = values[9];
                c_json.youtube = values[10];
                c_json.googlemap = values[11];
                c_json.price_link_name = values[12];
                c_json.popularity = values[13];
                c_json.index = place_index; 
                ret_data.spots.push(c_json);  
                place_index++;
            }
            else if(c_json.type == 'FOOD') {
                //id, name_kor, img, img2, img3 subtype, website, description, menu, opening_hour, youtube, google_map_link, popularity, name_local 
                c_json.name = values[2];
                c_json.img = values[3];
                c_json.img2 = values[4];
                c_json.img3 = values[5];
                c_json.subtype = values[6];
                c_json.website = values[7];
                c_json.description = values[8];
                c_json.menu = values[9];
                c_json.opening_hour = values[10];
                c_json.youtube = values[11];
                c_json.googlemap = values[12];
                c_json.popularity = values[13];
                c_json.index = food_index; 
                ret_data.spots.push(c_json);  
                food_index++; 
            }
            else if(c_json.type == 'HOTEL') {
                // id, name_kor, img, img2, img3, website, description, agoda_id, subtype, year_opened, year_renovated, number_of_rooms, star_rating, agoda_rates_from_usd, agoda_chain_id, chain_name, checkin_time, checkout_time, youtube, name_local
                c_json.name = values[2];
                c_json.img = values[3];
                c_json.img2 = values[4];
                c_json.img3 = values[5];
                c_json.hotel_website = values[6];
                c_json.description = values[7];
                c_json.agoda_id = values[8];
                //c_json.subtype = values[13]+'성급 '+values[9];
                c_json.subtype = TMI_ConvertHotelPriceText(values[14]);
                c_json.year_opened = values[10];
                c_json.year_renovated = values[11];
                c_json.number_of_rooms = values[12];
                //c_json.star_rating = values[13];
                //c_json.agoda_rates_from_usd = values[14];
                c_json.agoda_chain_id = values[15];
                c_json.chain_name = values[16];
                c_json.checkin_time = values[17];
                c_json.checkout_time = values[18];
                c_json.youtube = values[19];
                c_json.index = hotel_index; 
                ret_data.spots.push(c_json);  
                hotel_index++;
            }
            else if(c_json.type == 'ETC') {
                // id, name_kor, img, subtype, website, description, opening_hour, youtube, google_map_link, name_local 
                c_json.name = values[2];
                c_json.img = values[3];
                c_json.img2 = '';
                c_json.img3 = '';
                c_json.subtype = values[4];
                c_json.website = values[5];
                c_json.description = values[6];
                c_json.opening_hour = values[7];
                c_json.youtube = values[8];
                c_json.googlemap = values[9];
                c_json.index = etc_index; 
                ret_data.spots.push(c_json);  
                etc_index++;
            }
            else {
                console.log("ERROR: Invalid data type!")   ;
            }    
        }
        

    }

/*
    var ret_data = {};
    ret_data.map_url = 'https://www.google.com/maps/d/embed?mid=12eefVMssXrmw0ujP7YnqeLpbjT0PTbk&ehbc=2E312F&noprof=1';
    ret_data.spots = [];

    var p_item = {};
    p_item.type = 'PLACE';
    p_item.name = '세인트폴 성당 유적';
    p_item.subtype = '성당';
    p_item.img = 'https://lh3.googleusercontent.com/places/AJDFj41j8zxwDyDRHFAiYyV7T0TjVmEVooR3hWd4cYjZ12qMet34Ay-KAORJ-rQMA29okUaSTWfOWrBqUXZAu5uyIrAxWKJIJfTkCu4=s1600-w960-h200';
    ret_data.spots.push(p_item);


    var f_item = {};
    f_item.type = 'FOOD';
    f_item.name = '웡치케이';
    f_item.subtype = '광동식';
    f_item.img = 'https://lh3.googleusercontent.com/places/AJDFj409zRyxOb6YmgyenhqcmnKypkw0pKe2aSnTXiA7oIQ3MkzGVS4K_zoIizP6yXKy8eqTv4DIuod3kErRFUx8hEFEsu-zFPgYJwE=s1600-w4624-h200';
    ret_data.spots.push(f_item);

    
    var h_item = {};
    h_item.type = 'HOTEL';
    h_item.name = '베네시안 마카오';
    h_item.subtype = '호텔';
    h_item.img = 'https://lh3.googleusercontent.com/places/AJDFj43Pv2z8U-txgUGuKi2VnzPIJitj07GuR0nXix7m4z23RDeM37ddPuMO9uQ2eNWtMGO5HfttbYObUupuzJyyifI0hmYyrx-9vGQ=s1600-w754-h200';
    ret_data.spots.push(h_item);

*/
    return ret_data;
}





export function TMI_getHotelYearText(hd) {

    console.log(hd.year_opened);
    console.log(hd.year_renovated);

    var ret = "";
    if(hd.year_opened !== "") {
        ret = hd.year_opened+"년 오픈";
    }
    if(hd.year_renovated !== "") {
        ret = hd.year_renovated+"년 리모델링";
    }

    return ret;
}






// ---------------------------------------------------------------------------------------------------------------------------------------



export function PINO_ParseSpotData(raw_data) {

    var spot_json = {};
    spot_json.hotels = [];
    spot_json.foods = [];
    spot_json.attractions = [];
    
    const rows = raw_data.split("\r\n");
    for(let i = 0; i < rows.length; i++){
        //console.log(rows[i]);

        let values = rows[i].split("	");

        if(values[0] == 'HOTEL') {
            var h_json = {};
            h_json.hotel_id = values[1];
            h_json.chain_id = parseInt(values[2]);
            if( REGISTERED_CHAIN_IDS.has(h_json.chain_id) === false ) {
                h_json.chain_id = 0;
            }
            h_json.hotel_name = values[3];
            h_json.lat = parseFloat(values[4]);
            h_json.lng = parseFloat(values[5]);
            h_json.checkin_time = values[6];
            h_json.checkout_time = values[7];
            h_json.photo_1 = values[8];
            h_json.photo_2 = values[9];
            h_json.photo_3 = values[10];
            h_json.photo_4 = values[11];
            h_json.photo_5 = values[12];
            h_json.rates_from = values[13];
            h_json.number_of_reviews = values[14];
            h_json.rating_average = values[15];
            h_json.number_of_floors = values[16];
            h_json.year_opened = values[17];
            h_json.year_renovated = values[18];
            h_json.number_of_rooms = values[19];
            h_json.hotel_star = values[20] + "성급";
            h_json.address = values[21];
            h_json.google_id = values[22];
            h_json.region = values[23];
            h_json.options = values[24];
        
            //h_json.price = "가격 검색중";
            //h_json.price_link = AGODA_LINK_URL+h_json.hotel_id;
    
            spot_json.hotels.push(h_json);
        }
        else if(values[0] == 'FOODS') {
            var f_json = {};
            f_json.title = values[1];

            spot_json.foods.push(f_json);            
        }
        else if(values[0] == 'ATTRACTIONS') {
            var a_json = {};
            a_json.title = values[1];

            spot_json.attractions.push(a_json);
        }
        

    }

    

    return spot_json;
}









export const AGODA_LINK_URL = 'https://www.agoda.com/partners/partnersearch.aspx?pcs=1&cid=1900596&hl=ko-kr&hid='; 

//export const PINO_API_URL = 'https://hj26v621uh.execute-api.ap-northeast-2.amazonaws.com/prod';
//export const PINO_API_URL = 'https://d2q1hc1r7ctewq.cloudfront.net';
//export const PINO_API_URL = 'https://api.hocancesitga.com';
export const PINO_API_URL = 'https://api.pricemate.link';


const API_CACHE_DAYS = 100;    // 10 일 = 86400초 x 10일 x 1000 msec

export const YOUTUBE_API_URL = 'https://www.googleapis.com/youtube/v3';
export const YOUTUBE_API_KEY = 'AIzaSyAtpGDKBEoXaNjRJ_sTeykecnmxFhkadk4';


const HOTEL_RENOVATION_YEAR_LIMIT = 2018;
const HOTEL_OPEN_YEAR_LIMIT = 2018;


export const Google_map_req_libs = ['places'];

export const REGISTERED_CHAIN_IDS = new Map([
    //[-1, "전체"],
    [14, "힐튼"],
    [21, "메리어트"],
    [15, "하얏트"],
    [16, "IHG"],
    [3, "아코르"],
    [3580, "신라"],
    [808, "롯데"],
    [0, "기타"]
])





export function PINO_getHotelYearText(hd) {

    var ret = "";
    if(hd.year_renovated !== "" && parseInt(hd.year_renovated) > HOTEL_RENOVATION_YEAR_LIMIT) {
        ret = hd.year_renovated+"년 리모델링";
    }
    else if(hd.year_opened !== ""  && parseInt(hd.year_opened) > HOTEL_OPEN_YEAR_LIMIT) {
        ret = hd.year_opened+"년 개장";
    }

    return ret;
}

export function ChangeAgodaPhotoBigSize(url) {
    // https://pix5.agoda.net/hotelimages/24333639/0/c2aa1c269a9920d0cb1849a72a4ef69c.jpg?s=312x&ca=20&ce=0
    if(url !== undefined) {
        return url.replace('s=312x', 's=924x');
    }
    else {
        return "";
    }
}




export function PINO_ParseCityInfoData(row) {
    let values = row.split("	");
    
    var city_json = {};
    city_json.id = values[0];
    city_json.name = values[3];
    city_json.img = values[6];
    city_json.flight_hour = values[17];
    city_json.flight_min = values[18];
    city_json.layover = values[19];
            
    return city_json;
}




export function PINO_ParseHotelData(row) {
    let values = row.split("	");
    
    var hotel_json = {};
    hotel_json.hotel_id = values[0];
    hotel_json.chain_id = parseInt(values[1]);
    if( REGISTERED_CHAIN_IDS.has(hotel_json.chain_id) === false ) {
        hotel_json.chain_id = 0;
    }
    hotel_json.hotel_name = values[2];
    hotel_json.lat = parseFloat(values[3]);
    hotel_json.lng = parseFloat(values[4]);
    hotel_json.checkin_time = values[5];
    hotel_json.checkout_time = values[6];
    hotel_json.photo_1 = values[7];
    hotel_json.photo_2 = values[8];
    hotel_json.photo_3 = values[9];
    hotel_json.photo_4 = values[10];
    hotel_json.photo_5 = values[11];
    hotel_json.rates_from = values[12];
    hotel_json.number_of_reviews = values[13];
    hotel_json.rating_average = values[14];
    hotel_json.number_of_floors = values[15];
    hotel_json.year_opened = values[16];
    hotel_json.year_renovated = values[17];
    hotel_json.number_of_rooms = values[18];
    hotel_json.hotel_star = values[19] + "성급";
    hotel_json.address = values[20];
    hotel_json.google_id = values[21];
    hotel_json.region = values[22];
    hotel_json.options = values[23];

        
    //hotel_json.price = "가격 검색중";
    //hotel_json.price_link = AGODA_LINK_URL+hotel_json.hotel_id;

    return hotel_json;
}

export function PINO_IsExpiredDate(time_str) {
    var elap_days = (Date.now() - Date.parse(time_str)) / 86400000;
    if( elap_days > API_CACHE_DAYS) {
        console.log("PINO_IsExpiredDate - expired", elap_days);
        return true;
    }

    console.log("PINO_IsExpiredDate - not expired", elap_days);
    return false;
}




export function PINO_convertYoutubeVideoToPinoVideo(youtube_video_obj) {

    var pino_videos = [];
    for(var i=0;i<youtube_video_obj.items.length;i++) {
        var item = youtube_video_obj.items[i];
        
        var parsed_item = {};
        parsed_item.video_id = item.id.videoId;
        parsed_item.title = item.snippet.title;
        parsed_item.ch_id = item.snippet.channelId;
        parsed_item.ch_name = item.snippet.channelTitle;
        parsed_item.desc = item.snippet.description;
        parsed_item.upload_date = item.snippet.publishedAt;
        parsed_item.thumb = item.snippet.thumbnails.medium.url;

        pino_videos.push(parsed_item);
    }

    return pino_videos;

}


export function PINO_convertGooglePlaceToPinoPlace(google_place_obj) {
    var pino_place = {};
    pino_place.place_id = google_place_obj.place_id;
    pino_place.name = google_place_obj.name;
    pino_place.types = google_place_obj.types;
    pino_place.lat = google_place_obj.geometry.location.lat();
    pino_place.lng = google_place_obj.geometry.location.lng();
    pino_place.address = google_place_obj.formatted_address;
    pino_place.google_url = google_place_obj.url;
    if("formatted_phone_number" in google_place_obj) {
        pino_place.phone = google_place_obj.formatted_phone_number;
    }
    if("website" in google_place_obj) {
        pino_place.website = google_place_obj.website;
    }
    if("photos" in google_place_obj) {
        pino_place.photos = [];
        for(var i=0;i<1;i++) {
            var photo_item = {};
            photo_item.url = google_place_obj.photos[i].getUrl();
            console.log("★★★★★★★★★★ CHARGED : GET URL = 7")
            photo_item.html_attributions = google_place_obj.photos[i].html_attributions[0];
            pino_place.photos.push(photo_item);
        }
    }
    if("opening_hours" in google_place_obj) {
        pino_place.opening_hours = [];
        for( i=0;i<google_place_obj.opening_hours.weekday_text.length;i++) {
            pino_place.opening_hours.push(google_place_obj.opening_hours.weekday_text[i]);
        }
    }
    if("rating" in google_place_obj) {
        pino_place.rating = google_place_obj.rating;
    }
    if("user_ratings_total" in google_place_obj) {
        pino_place.rating_count = google_place_obj.user_ratings_total;
    }
    else {
        pino_place.rating_count = 0;
    }
    if("reviews" in google_place_obj) {
        pino_place.reviews = [];
        for( i=0;i<google_place_obj.reviews.length;i++) {
            var rv_item = {};
            rv_item.text = google_place_obj.reviews[i].text;
            rv_item.rating = google_place_obj.reviews[i].rating;
            rv_item.author_name = google_place_obj.reviews[i].author_name;
            rv_item.author_url = google_place_obj.reviews[i].author_url;
            rv_item.author_img = google_place_obj.reviews[i].profile_photo_url;
            rv_item.time = google_place_obj.reviews[i].time;
            rv_item.lang = google_place_obj.reviews[i].language;
            pino_place.reviews.push(rv_item);
        }
    } 
    if("address_components" in google_place_obj) {
        for( i=0;i<google_place_obj.address_components.length;i++) {
            if(google_place_obj.address_components[i].types.indexOf('country')>=0) {
                pino_place.cc = google_place_obj.address_components[i].short_name;
                break;
            }
        }
    }

    pino_place.saved_timestamp = Date.now();
    
    return pino_place;
}



export function PINO_DisplayDateText(date) {
    if(date == undefined) {
        return "";
    }
    return (date.getMonth()+1) + "월" + date.getDate() + "일";
}


export function PINO_convertEpochToDisplayTime(epoch_time) {

    var input = epoch_time;
    if(input < 10000000000) {
        input *= 1000;
    }

    var date = new Date(input);

    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();

    return year+"-"+month+"-"+day;
}


/*
const PinoUtil = {
    AGODA_LINK_URL : 'https://www.agoda.com/partners/partnersearch.aspx?pcs=1&cid=1900596&hl=ko-kr&hid='

    
};
  
export default PinoUtil;
*/






const PINO_TOUR_DATE_KEY = 'pino_tour_date';

export function PINO_saveTourDate(st_date, end_date, num_adult = 2, num_children = 0) {
    var save_item = {};
    save_item.st_date = st_date;
    save_item.end_date = end_date;
    save_item.num_adult = num_adult;
    save_item.num_children = num_children;
    localStorage.setItem(PINO_TOUR_DATE_KEY, JSON.stringify(save_item));
}


export function PINO_loadTourDate() {
    var ret_data = {};
    var today = new Date();

    var td = localStorage.getItem(PINO_TOUR_DATE_KEY);
    if(td != null) {
        ret_data = JSON.parse(td);
        var saved_st_date = new Date(ret_data.st_date);
        if(saved_st_date > today & 'num_adult' in ret_data & 'num_children' in ret_data) { //  saved date is valid
            return ret_data;
        }
        else {      // saved date is expired
            localStorage.removeItem(PINO_TOUR_DATE_KEY);
        }
    }

    // return new default today date
    var today_str =  today.getFullYear() +"-"+ ("0" + (1 + today.getMonth())).slice(-2) +"-"+ ("0" + today.getDate()).slice(-2);
    var tomorrow = new Date(today.setDate(today.getDate()+1));
    var tomorrow_str =  tomorrow.getFullYear() +"-"+ ("0" + (1 + tomorrow.getMonth())).slice(-2) +"-"+ ("0" + tomorrow.getDate()).slice(-2);
    ret_data.st_date = today_str;
    ret_data.end_date = tomorrow_str;  
    ret_data.num_adult = 2;  
    ret_data.num_children = 0;  
    return ret_data;    
}


export function PINO_getDispDateText(date) {
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);

    // yyyy-mm-dd 형식의 문자열로 변환합니다.
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
}