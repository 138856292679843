import React, {useEffect, useState} from "react";
import {Link, useNavigate } from "react-router-dom";
import "./Header.css";
import * as PinoUtil from "./Common/PinoUtil";

function Header(props) {    
    const [loca, setLoca] = useState("");
    const navigate  = useNavigate();

    useEffect(()=> {
        //console.log("useEffect - header");
        ParseURL();

    }, [navigate]);


    const ParseURL = () => {
        //console.log("ParseURL", window.location.pathname);
        var path = window.location.pathname.split('/')[1];
        //console.log(path);
        setLoca(path);
    }

    const IsMainPage = (loca_url) => {
        //console.log("kkk",loca_url);
        if(loca_url == "" ) {
            return true;
        }  
        return false;
    }

    return (
        <div id='header'>
            
            <div id='header_logo_div'>
                <Link to='/ ' > 
                    <img id='header_logo_img' src='/res/tmimap-logo-07.png' alt="트래블TMI"/>
                </Link>
            </div>


            {(IsMainPage(loca)==true)?
                <></>
                :<div id='header_sub_loc_div'>
                    {/*<Link to={{pathname:`./${loca}`, }} > */}
                    <Link to='/'>
                        <span id='header_subtitle'> { PinoUtil.PINO_CITY_LIST.get(loca).city_name }</span>
                    </Link>
                </div>
            }

            
        </div>
    );
}

export default Header; 